require("normalize.css/normalize.css");
require("./styles/index.scss");

import $ from "jquery";
import LazyLoad from "vanilla-lazyload";

import { gsap } from "gsap";

import Swiper from "swiper";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/swiper.min.css";

var lazyLoadInstance = new LazyLoad();

$(window).on("load", function () {
    setTimeout(function () {
        $("body").addClass("loaded");
    }, 1000);
});

document.addEventListener("DOMContentLoaded", function () {
    const clientsSlider = new Swiper(".clients-slider-container", {
        modules: [Navigation, Pagination],
        loop: true,

        pagination: {
            el: ".clients-slider-nav-pagination",
            type: "custom",
            renderCustom: function (swiper, current, total) {
                return current + "/" + total;
            },
        },

        navigation: {
            nextEl: ".clients-slider-nav-next",
            prevEl: ".clients-slider-nav-prev",
        },

        on: {
            slideChange: function (swiper) {
                $(
                    ".clients-slider-photos .clients-slider-photos-item.active"
                ).removeClass("active");
                $(
                    ".clients-slider-photos .clients-slider-photos-item:nth-child(" +
                        (swiper.realIndex + 1) +
                        ")"
                ).addClass("active");
            },
        },
    });

    $(".tech-labels-row").each(function () {
        for (var i = 0; i < 2; i++) {
            $(this).children().clone().appendTo(this);
        }
    });

    scrollAnimation();
});

setTimeout(function () {
    $("body").addClass("loaded");
}, 30000);

function calculateVh(initial) {
    var vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", vh + "px");
    if (initial == true)
        document.documentElement.style.setProperty("--ivh", vh + "px");
}

calculateVh(true);
window.addEventListener("resize", calculateVh);
window.addEventListener("orientationchange", calculateVh(true));

$(document).on("click", 'a[href^="#"]:not([href="#"])', function (event) {
    event.preventDefault();

    $("html, body").animate(
        {
            scrollTop:
                $($.attr(this, "href")).offset().top - $("header").height(),
        },
        500
    );
});

$(".header-nav-handler").on("click", function (event) {
    event.preventDefault();
    $("body").toggleClass("nav-visible");
});

$(".mobile-nav a").on("click", function (event) {
    $("body").removeClass("nav-visible");
});

$(".form-preview-handler").on("click", function (event) {
    event.preventDefault();
    $("body").addClass("preview-visible");
});

$(".preview-close").on("click", function (event) {
    event.preventDefault();
    $("body").removeClass("preview-visible");
});

$(".preview").on("click", function (e) {
    if (e.target != this) return false;
    $("body").removeClass("preview-visible");
});

$(".form-sent-close").on("click", function (event) {
    event.preventDefault();
    $("body").removeClass("form-sent-visible");
});

$(".form-sent").on("click", function (e) {
    if (e.target != this) return false;
    $("body").removeClass("form-sent-visible");
});

$(window).on("scroll", function () {
    scrollAnimation();
});

function scrollAnimation() {
    var labelsY = $(".tech-labels").offset().top,
        scrollPos = $(window).scrollTop() + $(window).height();
    if (
        scrollPos > labelsY &&
        scrollPos <
            labelsY + $(".tech-labels").outerHeight() + $(window).height()
    ) {
        var overflow = ((scrollPos - labelsY) / $(window).height()) * 50;
        gsap.to(".tech-labels-row:nth-child(2n-1)", {
            x: "-" + overflow + "%",
            duration: 2,
        });
        gsap.to(".tech-labels-row:nth-child(2n)", {
            x: overflow + "%",
            duration: 2,
        });
    }

    var headY = $(".experts-dingo-head").offset().top;
    if (
        scrollPos > headY &&
        scrollPos <
            headY + $(".experts-dingo-head").outerHeight() + $(window).height()
    ) {
        var overflow = ((scrollPos - headY) / $(window).height()) * 20;
        gsap.to(".experts-dingo-head", {
            y: overflow + "%",
            duration: 2,
        });
    }

    var introY = $(".intro").offset().top;
    if (
        scrollPos > introY &&
        scrollPos < introY + $(".intro").outerHeight() + $(window).height()
    ) {
        var overflow = ((scrollPos / $(window).height()) * 100 - 100) / 5;
        gsap.to(".intro-image", {
            y: "-" + overflow + "%",
            duration: 2,
        });
    }
}

const handleSubmit = (event) => {
    event.preventDefault();

    const myForm = event.target;
    const formData = new FormData(myForm);

    fetch(".netlify/functions/email-response", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams(formData).toString(),
    })
        .then((response) => {
            if (!response.ok) {
                throw new Error("Not 2xx response", { cause: response });
            } else {
                $("body").addClass("form-sent-visible");
                document.querySelector("#subscribe").reset();
            }
        })
        .catch((error) => alert(error));
};

document.querySelector("#subscribe").addEventListener("submit", handleSubmit);
